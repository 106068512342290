<template>
  <div
    id="users"
    class="page-container-table"
  >
    <button-all-header
      :contentBtnAdd="'Thêm người lao động'"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @clickAdd="$bvModal.show('modal-add-user')"
      @search="search($event)"
    />
    <vue-good-table
      ref="user-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'birthDay'">
          {{ props.row.birthDay | formatDate }}
        </span>
        <span v-else-if="props.column.field === 'gender'">
          <span v-if="props.row.gender === true">Nam</span>
          <span v-if="props.row.gender === false">Nữ</span>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
    <modal-add-user @addSuccess="getListUser" />
    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa người dùng không ?"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddUser from './ModalAddUser.vue'
import store from '../../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    ConfirmModal,
    VBTooltip,
    ButtonAllHeader,
    ModalAddUser,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      queryParams: {
        bussinessRecruitmentId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevelString',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,
      showBtnMultiDelete: false,
      deletedIds: [],
    }
  },
  created() {
    this.queryParams.bussinessRecruitmentId = this.$route.params.id
    this.getListUser()
  },
  methods: {
    async getListUser() {
      this.$showLoading()
      const { data } = await store.getListUser(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListUser()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListUser()
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['user-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    async acceptDelete() {
      const params = {
        bussinesssRecruitmentId: this.queryParams.bussinessRecruitmentId,
        ids: this.deletedIds,
      }
      const res = await store.deleteUser(params)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa người dùng thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListUser()
      }
    },
  },
}
</script>
