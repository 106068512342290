<template>
  <b-modal
    id="modal-add-user"
    size="lg"
    title="Thêm người lao động"
    centered
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    :ok-disabled="disableOk"
    @shown="getListUser"
    @ok="handleOk"
  >
    <div class="header-action">
      <search @change="search" />
    </div>
    <vue-good-table
      ref="modal-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
    >

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'gender'">
          <span v-if="props.row.gender === true">
            Nam
          </span>
          <span v-if="props.row.gender === false">
            Nữ
          </span>
        </span>
        <span v-else-if="props.column.field === 'birthDay'">
          {{ props.row.birthDay | formatDate }}
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    BModal,
  },
  data() {
    return {
      queryParams: {
        bussinessRecruitmentId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevelString',
          sortable: false,
        },
      ],
      dataList: [],
      totalRecord: 0,
      disableOk: false,
    }
  },
  created() {
    this.queryParams.bussinessRecruitmentId = this.$route.params.id
  },
  methods: {
    async getListUser() {
      this.$showLoading()
      const { data } = await store.getListUserModal(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListUser()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListUser()
    },
    async handleOk(e) {
      e.preventDefault()
      const listIds = this.$refs['modal-table'].selectedRows.map(item => item.id)
      if (listIds.length === 0) {
        this.$root.$bvToast.toast('Vui lòng lựa chọn ít nhất một người dùng.', {
          title: 'Thông báo',
          variant: 'warning',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        const params = {
          bussinesssRecruitmentId: this.queryParams.bussinessRecruitmentId,
          registerJobWorkerIds: listIds,
        }
        this.disableOk = true
        const res = await store.addUser(params)
        if (res.success) {
          this.$root.$bvToast.toast('Thêm người dùng thành công.', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$emit('addSuccess')
          this.$nextTick(() => {
            this.$bvModal.hide('modal-add-user')
          })
        }
        this.disableOk = false
      }
    },
  },
}
</script>
