import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getComboboxBusiness() {
    try {
      const { data } = await axiosApiInstance.get('/Bussiness/get-combobox-bussiness')
      return data
    } catch (err) {
      return err
    }
  },
  async getListBusiness(payload) {
    try {
      const { data } = await axiosApiInstance.get('/IntroduceJob/bussinessRecruitments', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getDemand(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/BussinessRecruitment/bussinessRecruitments/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async getListUser(payload) {
    try {
      const { data } = await axiosApiInstance.get('/IntroduceJob/list-bussiness-recruitment-worker', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getListUserModal(payload) {
    try {
      const { data } = await axiosApiInstance.get('/IntroduceJob/list-workers-not-register', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addUser(payload) {
    try {
      const { data } = await axiosApiInstance.post('/IntroduceJob/add-bussiness-recruitment-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteUser(payload) {
    try {
      const { data } = await axiosApiInstance.post('/IntroduceJob/delete-bussiness-recruitment-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
